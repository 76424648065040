<template>
  <div class="pricingtable_leftarea">
    <ul>
      <li v-for="(item, inx) in commercialsList" :key="inx">
        <span>
          <img :src="iconList[item.serviceGroup]"/>
        </span> {{ item.commercialName }}
      </li>
      <!-- <li>
        <span>
          <img src="/assets/sabre-img/trolley1.png" />
        </span>{{$t("sabre.booking.ticket-box.hand-bag")}}
      </li>
      <li>
        <span>
          <img src="/assets/sabre-img/case.png" />
        </span>{{$t("sabre.booking.ticket-box.briefcase")}}
      </li>
      <li>
        <span>
          <img src="/assets/sabre-img/foodicon.png" />
        </span>{{$t("sabre.booking.ticket-box.briefcase")}}
      </li>
      <li>
        <span>
          <img src="/assets/sabre-img/airplane4.png" />
        </span>{{$t("sabre.booking.ticket-box.card-change")}}
      </li>
      <li>
        <span>
          <img src="/assets/sabre-img/airplane3.png" />
        </span>{{$t("sabre.booking.ticket-box.cancel-card")}}
      </li>
      <li>
        <span>
          <img src="/assets/sabre-img/chair.png" />
        </span>{{$t("sabre.booking.ticket-box.seating")}}
      </li> -->
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ticket-selection-right-part',
  props: {
    commercials: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      brandFeatureDescs: 'GET_SABRE_BOOKING_BRAND_DESC',
    }),
    commercialsList() {
      const { commercials, brandFeatureDescs } = this;
      return Object.keys(commercials).map((key) => {
        const one = brandFeatureDescs.find((desc) => desc.id === commercials[key][0]) || null;
        return {
          commercialName: key,
          serviceType: one?.serviceType || '',
          serviceGroup: one?.serviceGroup || '',
        };
      });
    },
  },
  data: () => ({
    iconList:
      { BF: '/assets/sabre-img/trolley1.png',
        BG: '/assets/sabre-img/chair.png',
        LG: '/assets/sabre-img/chair.png',
        TS: '/assets/sabre-img/airplane3.png',
        ML: '/assets/sabre-img/case.png',
        IE: '/assets/sabre-img/airplane4.png',
        SA: '/assets/sabre-img/foodicon.png' }
    ,
  }),
};
</script>
